<template>
    <div class="error-page">
        <h1>404</h1>
        
        <div class="story__top-line">
            <p>PAGE NOT FOUND</p>
            <div class="dot">•</div>
            <div class="dot">•</div>
            <div class="dot">•</div>
            <div class="bar bar--right"></div>
        </div>

        <site-footer />
    </div>

</template>

<script>
import Footer from '@/components/Footer'

    export default {
        components: {
            'site-footer': Footer
        },
    }
</script>